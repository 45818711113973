<template>
  <div id="mapa-calor"></div>
</template>

<script>
import L from 'leaflet';
import PreviewMapaMixins from './PreviewMapaMixins';

const corPadrao = '#FF0000';

export default {
  mixins: [PreviewMapaMixins],

  data() {
    return {
      mapaId: 'mapa-calor'
    };
  },

  mounted() {
    this.desenhaMapa();
  },

  computed: {
    campoParaClassificacao() {
      return this.$store.getters.getSimbologiaConfigMapaCalor
        .campoParaClassificacao;
    },

    raioPontos() {
      return this.$store.getters.getSimbologiaConfigMapaCalor.raioPontos;
    },

    desfoquePontos() {
      return this.$store.getters.getSimbologiaConfigMapaCalor.desfoquePontos;
    }
  },

  watch: {
    campoParaClassificacao() {
      this.desenhaMapa();
    },

    raioPontos() {
      this.desenhaMapa();
    },

    desfoquePontos() {
      this.desenhaMapa();
    }
  },

  methods: {
    plotaFeicoesMapa() {
      // this.plotaPontosComPopup();

      if (!this.campoParaClassificacao) return;

      this.plotaMapaCalor();
    },

    // plotaPontosComPopup() {

    //   const pointToLayer = (feature, latlng) => {

    //     return L.circleMarker(latlng, {
    //       radius: this.getMapFeatureRadius
    //     });
    //   }

    //   this.linhasRelatorio.forEach(linha => {

    //     let layer = L.geoJSON(JSON.parse(linha.geom), {
    //       style: (feature) => {

    //         return {
    //           color: corPadrao,
    //           fillColor: corPadrao,
    //           fillOpacity: this.getMapFeatureFillOpacity,
    //           weight: this.getMapFeatureWeight
    //         };
    //       },
    //       pointToLayer
    //     });

    //     let msgPopup = this.msgPopup(linha);
    //     if (msgPopup) layer.bindPopup(msgPopup);

    //     layer.addTo(this.map);
    //   });
    // },

    plotaMapaCalor() {
      let max = 1.0;

      const points = this.linhasRelatorio.map((linha) => {
        let dadoParaClassificacao = linha[this.campoParaClassificacao];
        let point = JSON.parse(linha.geom);

        if (dadoParaClassificacao > max) {
          max = dadoParaClassificacao;
        }

        return [...point.coordinates.reverse(), dadoParaClassificacao];
      });

      // minOpacity - the minimum opacity the heat will start at
      // maxZoom - zoom level where the points reach maximum intensity (as intensity scales with zoom), equals maxZoom of the map by default
      // max - maximum point intensity, 1.0 by default
      // radius - radius of each "point" of the heatmap, 25 by default
      // blur - amount of blur, 15 by default
      // gradient - color gradient config, e.g. {0.4: 'blue', 0.65: 'lime', 1: 'red'}

      L.heatLayer(points, {
        maxZoom: 7,
        max,
        radius: this.raioPontos,
        blur: this.desfoquePontos
      }).addTo(this.map);
    }
  }
};
</script>

<style>
#mapa-calor {
  min-height: 330px;
  max-height: 330px;
  z-index: 0;
}
</style>
